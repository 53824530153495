define("ember-user-activity/services/-private/event-manager", ["exports", "@ember/service", "@ember/object/events"], function (_exports, _service, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EventManagerService = classic(_class = class EventManagerService extends _service.default {
    // Partial Evented Implementation: https://github.com/emberjs/ember.js/blob/v3.16.1/packages/%40ember/-internals/runtime/lib/mixins/evented.js#L13
    // one() was omitted from this implementation due to the way the user-activity service would need a lot of special behavior added
    on(name, target, method) {
      (0, _events.addListener)(this, name, target, method);
      return this;
    }

    off(name, target, method) {
      (0, _events.removeListener)(this, name, target, method);
      return this;
    }

    trigger(name) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (0, _events.sendEvent)(this, name, args);
    }

  }) || _class;

  _exports.default = EventManagerService;
});